<template>
  <div>
    <div class="d-sm-block d-mb-block d-lg-none" style="overflow-x: hidden">
      <Header2></Header2>
      <div class="product-container-mobile" v-if="loading">
        <div class="product-first-col-mobile">
          <div class="breadcrumbs-category">
            <p>
              <router-link to="/" class="breadcrumbs">{{
                $t("mainpage")
              }}</router-link>
              >
              <router-link
                :to="{ path: `/categories/${this.$route.params.categoryId}` }"
                class="breadcrumbs"
                >{{ this.getCurrentCategory.name }}</router-link
              >
              > {{ this.product.name }}
            </p>
            <!-- <breadcrumbs :routes="routes"></breadcrumbs> -->
          </div>
          <div class="product-image-container">
            <img
              class="product-image-mobile"
              v-if="
                this.product.images.some((image) => image.type === 'Original')
              "
              :src="`https://api.rdprooff.ru/api/Image/GetResized?imageId=${this.product.images[0].id}&width=638&height=592&isKeepAspectRatio=true`"
            />
            <img
              class="product-image-mobile"
              v-else
              src="@/assets/img/image-default.png"
            />
          </div>
        </div>
        <div class="product-second-col-mobile">
          <p class="product-title">{{ this.product.name }}</p>
          <div class="product-weight-container">
            <p class="product-weight">{{ $t("weight") }}</p>
            <p class="product-weight-num">
              {{ this.product.weight }}
              {{ getWeightTranslation(this.product.uom) }}
            </p>
          </div>
          <div class="product-info-container">
            <div class="product-info-content py-1">
              <p class="product-info-text">
                <span class="product-info-header">{{
                  $t("manufacturer")
                }}</span>
                {{ this.product.manufacturer }}
              </p>
            </div>
            <div class="product-info-content py-1">
              <p class="product-info-text">
                <span class="product-info-header">{{ $t("country") }}</span>
                {{ this.product.origin }}
              </p>
            </div>
            <div class="product-info-content py-1">
              <p class="product-info-text">
                <span class="product-info-header">{{ $t("package") }}</span>
                {{ getPackageTypeTranslation(this.product.packageType) }}
              </p>
            </div>
            <div class="product-info-content py-1">
              <p class="product-info-text">
                <span class="product-info-header">{{ $t("group") }}</span>
                <span v-if="this.product.categories.length === 1">
                    {{ this.product.categories[0].name }}
                  </span>
                  <span v-else>
                    <span
                      v-for="(category, index) in this.product.categories.filter(cat => cat.stateCode === 'Active')"
                      :key="index"
                    >
                      {{ category.name }}
                      <span v-if="index < product.categories.filter(cat => cat.stateCode === 'Active').length - 1">
                        /
                      </span>
                    </span>
                  </span>
              </p>
            </div>
          </div>
          <v-col cols="12" class="title-container-tabs">
            <template>
              <div v-if="loading">
                <v-tabs v-model="activeTab">
                  <v-tab v-for="(tab, index) in tabs" :key="index">
                    {{ $t(`${tab.title}`) }}
                  </v-tab>

                  <v-tab-item v-for="(tab, index) in tabs" :key="index">
                    <template v-slot:default>
                      <component
                        :is="tab.component"
                        :responseData="product"
                      ></component>
                    </template>
                  </v-tab-item>
                </v-tabs>
              </div>
            </template>
          </v-col>
        </div>
      </div>
    </div>
    <div class="d-none d-lg-block d-xl-block d-xxl-block">
      <Header2></Header2>
      <div class="product-container" v-if="loading">
        <div class="product-first-col">
          <div class="breadcrumbs-category">
            <p>
              <router-link to="/" class="breadcrumbs">{{
                $t("mainpage")
              }}</router-link>
              >
              <router-link
                :to="{ path: `/categories/${this.$route.params.categoryId}` }"
                class="breadcrumbs"
              >
                {{ this.getCurrentCategory.name }}</router-link
              >
              > {{ this.product.name }}
            </p>
            <!-- <breadcrumbs :routes="routes"></breadcrumbs> -->
          </div>
          <div class="product-image-container">
            <!-- <div class="product-imgs">
        <div class="img-display">
            <div class="img-showcase">
                <img v-for="(img, index) in images" :key="index" :src="img" alt="shoe image">
                <img class="product-image"
            v-if="this.product.images.some(image => image.type === 'Medium')"
            :src="`${imgPathBase}${this.product.images.find(image => image.type === 'Medium').imagePath}`"
            />
            </div>
        </div>
        <div class="img-select">
            <div class="img-item" v-for="(img, index) in images" :key="index">
                <a href="#" @click="changeImage(index + 1)" :data-id="index + 1">
                    <img :src="img" alt="shoe image">
                </a>
            </div>
        </div>
    </div> -->
            <img
              class="product-image"
              v-if="
                this.product.images.some((image) => image.type === 'Original')
              "
              :src="`https://api.rdprooff.ru/api/Image/GetResized?imageId=${this.product.images[0].id}&width=638&height=592&isKeepAspectRatio=true`"
            />
            <img
              class="product-image-not-load"
              v-else
              src="@/assets/img/image-default.png"
            />
          </div>
        </div>
        <div class="product-second-col">
          <p class="product-title">{{ this.product.name }}</p>
          <div class="product-weight-container">
            <p class="product-weight">{{ $t("weight") }}</p>
            <p class="product-weight-num">
              {{ this.product.weight }}
              {{ getWeightTranslation(this.product.uom) }}
            </p>
          </div>
          <div class="product-info-container">
            <div class="product-info-content py-1">
              <p class="product-info-text">
                <span class="product-info-header">{{
                  $t("manufacturer")
                }}</span>
                {{ this.product.manufacturer }}
              </p>
            </div>
            <div class="product-info-content py-1">
              <p class="product-info-text">
                <span class="product-info-header">{{ $t("country") }}</span>
                {{ this.product.origin }}
              </p>
            </div>
            <div class="product-info-content py-1">
              <p class="product-info-text">
                <span class="product-info-header">{{ $t("package") }}</span>
                {{ getPackageTypeTranslation(this.product.packageType) }}
              </p>
            </div>
            <div class="product-info-content py-1">
              <p class="product-info-text">
                <span class="product-info-header">{{ $t("group") }}</span>
                  <span v-if="this.product.categories.length === 1">
                    {{ this.product.categories[0].name }}
                  </span>
                  <span v-else>
                    <span
                      v-for="(category, index) in this.product.categories.filter(cat => cat.stateCode === 'Active')"
                      :key="index"
                    >
                      {{ category.name }}
                      <span v-if="index < product.categories.filter(cat => cat.stateCode === 'Active').length - 1">
                        /
                      </span>
                    </span>
                  </span>
                <!-- for если несколько категорий выводить их все -->
                <!-- {{ this.product.categories[0].name }} -->
              </p>
            </div>
          </div>
          <v-col cols="12" class="title-container-tabs">
            <template>
              <div v-if="loading">
                <v-tabs v-model="activeTab">
                  <v-tab v-for="(tab, index) in tabs" :key="index">
                    {{ $t(`${tab.title}`) }}
                  </v-tab>

                  <v-tab-item v-for="(tab, index) in tabs" :key="index">
                    <template v-slot:default>
                      <component
                        :is="tab.component"
                        :responseData="product"
                      ></component>
                    </template>
                  </v-tab-item>
                </v-tabs>
              </div>
            </template>
          </v-col>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Header2 from "@/components/Header2.vue";
import ProductComp from "../components/ProductComp.vue";
import ProductDescription from "../components/ProductDescription.vue";
import Breadcrumbs from "@/components/Breadcrumbs.vue";
import { mapGetters } from "vuex";
export default {
  name: "ProductPage",
  components: {
    Header2,
    ProductComp,
    ProductDescription,
    Breadcrumbs,
  },
  // beforeRouteLeave(to, from, next) {
  //   if (from.name === 'ProductPage') {
  //     const currentProductId = this.$route.params.productId;
  //     localStorage.setItem('currentProductId', currentProductId);
  //   }
  //   next();
  // },
  data() {
    return {
      activeTab: 0,
      tabs: [
        { title: "Описание", component: ProductDescription },
        { title: "Состав", component: ProductComp },
      ],
      product: {},
      loading: false,
      routes: [
        // Передайте маршруты через пропс в компонент хлебных крошек
        { to: "/", meta: { breadcrumb: "Главная" } },
        {
          to: "/categories",
          title: "CurrentCategory",
          meta: { breadcrumb: "Конфеты и драже" },
        },
        {
          to: "/categories/product",
          title: "CurrentProduct",
          meta: { breadcrumb: "Шоколадная Мечта" },
        },
        // Добавьте другие маршруты по аналогии
      ],
      weightTranslation: {
        Gramm: "г.",
        Kilogramm: "кг.",
      },
      weightTranslationEn: {
        Gramm: "g.",
        Kilogramm: "kg.",
      },
      imgPathBase: "",
      packageTranslation: {
        PlasticBox: "Пластиковая коробка",
        PlasticBag: "Пакет",
        CardboardBox: "Подарочная коробка",
        Plastic: "Пластик",
        Can: "Банка",
        WoodenBox: "Картонная Коробка",
        Display: "Дисплей",
      },
      packageTranslationEn: {
        CardboardBox: "Gift Box",
        PlasticBag: "Plastic bag",
      },
      //   images: [
      //         'https://fadzrinmadu.github.io/hosted-assets/product-detail-page-design-with-image-slider-html-css-and-javascript/shoe_1.jpg',
      //         'https://fadzrinmadu.github.io/hosted-assets/product-detail-page-design-with-image-slider-html-css-and-javascript/shoe_2.jpg',
      //         'https://fadzrinmadu.github.io/hosted-assets/product-detail-page-design-with-image-slider-html-css-and-javascript/shoe_3.jpg',
      //         'https://fadzrinmadu.github.io/hosted-assets/product-detail-page-design-with-image-slider-html-css-and-javascript/shoe_4.jpg'
      // ],
      //     imgId: 1
    };
  },
  methods: {
    // changeImage(id) {
    //         this.imgId = id;
    //     },
    getProduct() {
      const locale = localStorage.getItem("app_locale");
      this.$http
        .get(`/api/Product/${this.$route.params.productId}`, {
          // pageNumber: this.currentPage,
          // pageSize: 100,
          headers: {
            CurrentLanguage: locale,
            "Content-Type": "application/json",
          },
        })
        .then((response) => {
          this.product = response.data;
          this.imgPathBase = process.env.VUE_APP_API_HOST;
          this.loading = true;
          //   this.totalPages = response.data.totalPages; // Общее количество страниц
          // this.totalItems = response.data.totalCount;
        })
        .catch((error) => (this.error = error));
    },
    getCategory() {
      const locale = localStorage.getItem("app_locale");
      this.$http
        .get(`/api/Category/${this.$route.params.categoryId}`, {
          // pageNumber: this.currentPage,
          // pageSize: 100,
          headers: {
            CurrentLanguage: locale,
            "Content-Type": "application/json",
          },
        })
        .then((response) => {
          this.category = response.data;
          this.$store.commit("setCurrentCategory", this.category);
          //   this.totalPages = response.data.totalPages; // Общее количество страниц
          // this.totalItems = response.data.totalCount;
        })
        .catch((error) => (this.error = error));
    },
    getWeightTranslation(weight) {
      // return this.weightTranslation[weight] || weight;
      const savedLocale = this.$i18n.locale;
      // Если локаль найдена в локальном хранилище, установите ее
      if (savedLocale === "ru") {
        return this.weightTranslation[weight] || weight;
      } else {
        return this.weightTranslationEn[weight] || weight;
      }
    },
    getPackageTypeTranslation(packagetype) {
      // Попытайтесь получить локаль из локального хранилища
      const savedLocale = this.$i18n.locale;
      // Если локаль найдена в локальном хранилище, установите ее
      if (savedLocale === "ru") {
        return this.packageTranslation[packagetype] || packagetype;
      } else {
        return this.packageTranslationEn[packagetype] || packagetype;
      }
    },
    // slideImage() {
    //         const displayWidth = document.querySelector('.img-showcase img:first-child').clientWidth;
    //         document.querySelector('.img-showcase').style.transform = `translateX(${- (this.imgId - 1) * displayWidth}px)`;
    //     }
  },
  created() {
    this.getProduct();
    this.getCategory();
  },
  computed: {
    ...mapGetters(["getCurrentCategory"]),
    currentLocale() {
      return this.$i18n.locale; // Получаем текущую локаль из хранилища Vuex
    },
    // currentImage() {
    //         return this.images[this.imgId - 1];
    //     }
  },
  watch: {
    currentLocale: {
      handler() {
        // Выполняйте запросы к API при изменении локали
        this.getProduct(); // Передаем новую локаль в функцию для обновления данных
        this.getCategory(); // Передаем новую локаль в другую функцию для обновления данных
      },
      immediate: true, // Вызывать обработчик сразу после создания компонента
    },
    // imgId() {
    //         this.slideImage();
    //     }
  },
};
</script>
<style scoped>
/* img{
    width: 100%;
    display: block;
}
.img-display{
    overflow: hidden;
}
.img-showcase{
    display: flex;
    width: 100%;
    transition: all 0.5s ease;
}
.img-showcase img{
    min-width: 100%;
}
.img-select{
    display: flex;
}
.img-item{
    margin: 0.3rem;
}
.img-item:nth-child(1),
.img-item:nth-child(2),
.img-item:nth-child(3){
    margin-right: 0;
}
.img-item:hover{
    opacity: 0.8;
}
@media screen and (min-width: 992px){
    .card{
        display: grid;
        grid-template-columns: repeat(2, 1fr);
        grid-gap: 1.5rem;
    }
    .card-wrapper{
        height: 100vh;
        display: flex;
        justify-content: center;
        align-items: center;
    }
    .product-imgs{
        display: flex;
        flex-direction: column;
        justify-content: center;
    }
    .product-content{
        padding-top: 0;
    }
} */
.product-first-col-mobile {
  margin-right: 20px;
}
.product-container-mobile {
  display: flex;
  justify-content: center;
  /* margin-top: 4.62962962963vh; */
  margin-top: 0px;
  margin-bottom: 51px;
  width: 100%;
  padding: 12px;
  margin-right: auto;
  margin-left: auto;
}
.product-second-col-mobile {
  width: 100%;
}
::v-deep.v-tabs .v-tabs-bar .v-tabs-slider {
  background: var(
    --07,
    #000
  ) !important; /* Укажите желаемый цвет для индикатора */
}
::v-deep .v-tab:before {
  background-color: gray;
  bottom: 0;
  content: "";
  left: 0;
  opacity: 0;
  pointer-events: none;
  position: absolute;
  right: 0;
  top: 0;
  transition: 0.3s cubic-bezier(0.25, 0.8, 0.5, 1);
}
::v-deep .theme--light.v-tabs > .v-tabs-bar .v-tab:not(.v-tab--active),
.theme--light.v-tabs > .v-tabs-bar .v-tab:not(.v-tab--active) > .v-icon,
.theme--light.v-tabs > .v-tabs-bar .v-tab:not(.v-tab--active) > .v-btn,
.theme--light.v-tabs > .v-tabs-bar .v-tab--disabled {
  color: var(--Gray-3, #828282);
  font-family: Montserrat;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}
::v-deep .v-tab {
  caret-color: #ff0000 !important;
  align-items: center;
  cursor: pointer;
  display: flex;
  flex: 0 1 auto;
  color: var(--07, #000);
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  justify-content: center;
  letter-spacing: normal;
  min-width: 90px;
  max-width: 360px;
  outline: none;
  padding: 0 16px;
  position: relative;
  text-align: center;
  -webkit-text-decoration: none;
  text-decoration: none;
  text-transform: none;
  transition: none;
  -webkit-user-select: none;
  -moz-user-select: none;
  user-select: none;
  font-family: "Montserrat";
}
.breadcrums-click {
  cursor: pointer;
}
.breadcrums-title {
  color: var(--02, #848484);
  font-family: Inter;
  font-size: 13px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  margin-bottom: 0px;
  text-decoration: none;
}
.breadcrums-container {
  display: flex;
  align-items: center;
  margin-top: 7px;
}
::v-deep .v-input__slot {
  border-style: none;
  margin-bottom: 0px;
}
.title-container-tabs {
  padding: 0px !important;
}
.product-container {
  /* margin-left: 304px;
  margin-right: 304px; */
  display: flex;
  justify-content: center;
  /* margin-top: 4.62962962963vh; */
  margin-top: 0px;
  margin-bottom: 81px;
  width: 100%;
  padding: 12px;
  margin-right: auto;
  margin-left: auto;
}
.product-image-container {
  display: inline-flex;
  /* padding: 0px 44px 0px 27px; */
  align-items: center;
  border-radius: 10px;
  /* border: 1px solid var(--05, #dae3ea); */
  background: var(--06, #fff);
}
.product-image {
  width: 100%;
  height: 100%;
}
.product-image-not-load {
  padding: 0px;
}
.product-first-col {
  margin-right: 32px;
}
.product-second-col {
  width: 640px;
}
.breadcrumbs-category {
  color: var(--01, #603725);
  font-family: Montserrat;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  margin-bottom: 32px;
}
.product-title {
  color: var(--01, #603725);
  font-family: Montserrat;
  font-size: 24px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  text-transform: uppercase;
  margin-top: 43px;
}
.product-weight-container {
  border-radius: 8px;
  border: 1px solid var(--03, #bcb648);
  background: var(--06, #fff);
  flex-shrink: 0;
  width: fit-content;
  height: 38px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 8px 10px;
  margin-bottom: 11px;
  margin-top: 11px;
}
.product-weight {
  color: var(--01, #603725);
  font-family: Montserrat;
  font-size: 18px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  text-transform: uppercase;
}
.product-weight-num {
  color: var(--01, #603725);
  font-family: Montserrat;
  font-size: 18px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  margin-left: 10px;
}
.product-info-container {
  text-align: start;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  max-width: 388px;
}
.product-info-header {
  color: var(--07, #000);
  font-family: Montserrat;
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  white-space: nowrap;
}
.product-info-text {
  color: var(--07, #000);
  font-family: Montserrat;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  white-space: normal;
}
.product-info-content {
  display: flex;
  align-items: flex-start;
}
@media screen and (max-width: 1264px) {
  .product-image-mobile {
    width: 100%;
    max-width: 640px;
  }
  .product-second-col-mobile {
    /* max-width: 640px; */
    width: 100%;
  }
}
@media screen and (max-width: 950px) {
  /* Задаем высоту картинки на фоне в vh (например, 50vh) */
  .product-container-mobile {
    flex-direction: column;
  }
  .product-image-mobile {
    width: 100%;
    max-width: 440px;
  }
  .product-container-mobile {
    padding: 32px;
  }
}
@media screen and (max-width: 650px) {
  .product-image-mobile {
    width: 100%;
    /* max-width: 340px; */
  }
  .product-container-mobile {
    padding: 16px;
  }
  .product-first-col-mobile {
    margin-right: 0px;
  }
}
</style>
