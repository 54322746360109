<template>
  <div>
    <div class="d-sm-block d-mb-block d-lg-none" style="overflow-x: hidden">
        <div class="product-compound-container">
        <div style="margin-top:18px">
            <div v-for="(item, index) in this.compoundOuter" :key="'outer-' + item.id + '-' + index">
                <p class="compound-text">
                <span class="compound-header">{{item.name}}:</span>{{item.description}}
                </p>
            </div>
            <div class="horizontal-line"></div>
            <div  v-for="(item, flavour) in this.compoundInner" :key="'inner-' + item.id + '-' + flavour">
                  <div v-if="item.name != ''">
                    <p class="compound-text">
                  <span class="compound-header">{{item.name}}:</span>{{item.description}}
                  </p>
                  </div>
                  <div v-else>
                    <p></p>
                  </div>
              </div>
            <div class="warning-container">
            <p class="warning-text">
                {{ this.warningText }}
            </p>
            </div>
            <p class="compound-gmo">{{ $t("warning-text-gmo") }}</p>
        </div>
        </div>
        </div>
        <div class="d-none d-lg-block d-xl-block d-xxl-block">
        <div class="product-compound-container">
        <div style="margin-top: 18px">
            <div v-for="(item, index) in this.compoundOuter" :key="'outer-' + item.id + '-' + index">
                <p class="compound-text">
                <span class="compound-header">{{item.name}}:</span>{{item.description}}
                </p>
            </div>
            <div class="horizontal-line"></div>
              <div  v-for="(item, flavour) in this.compoundInner" :key="'inner-' + item.id + '-' + flavour">
                  <div v-if="item.name != ''">
                    <p class="compound-text">
                  <span class="compound-header">{{item.name}}:</span>{{item.description}}
                  </p>
                  </div>
                  <div v-else>
                    <p></p>
                  </div>
              </div>
            <!-- <p class="compound-text">
            <span class="compound-header">Начинка:</span> Молочно-ореховый крем
            (сахар, растительные масла и жиры (пальмовое и пальмоядровое)), лесной
            орех 7%, сухое обезжиренное молоко 5%, сухая молочная сыворотка 5%,
            лактоза 5%, эмульгатор: соевый лецитин, ароматизатор: ванилин.
            </p>
            <p class="compound-text">
            <span class="compound-header">Ягодная:</span> ягодная начинка 5%
            (сахар, измельченная черника, какао-масло, ароматизаторы, регулятор
            кислотности (Е-330, лимонная кислота), глюкозный сироп, концентрат
            сока бузины).
            </p>
            <p class="compound-text">
            <span class="compound-header">Фисташковая:</span> измельченная
            фисташка 5%, натуральный ароматизатор: фисташка.
            </p>
            <p class="compound-text">
            <span class="compound-header">Хрустящая карамельная начинка:</span>
            хрустящая карамель (сахар, какао-масло, рисовый крахмал,
            антислеживающий агент: бикарбонат натрия (e500i), натуральный
            ароматизатор), натуральный ароматизатор карамели.
            </p>
            <p class="compound-text">
            <span class="compound-header">Начинка «Чизкейк»:</span> измельченная
            фисташка 5%, натуральный ароматизатор: вишневый чизкейк.
            </p> -->
            <div class="warning-container">
            <p class="warning-text">
                {{ this.warningText }}
            </p>
            </div>
            <p class="compound-gmo">{{ $t("warning-text-gmo") }}</p>
        </div>
        </div>
        </div>
  </div>
</template>
<script>
export default {
  name: "ProductComp",
  props: {
    responseData: {},
  },
  data() {
    return {
        compoundOuter:{},
        compoundInter:{},
        warningText:""
    };
  },
  // created() {
  //   this.compoundOuter = this.responseData.outerComponents;
  //   this.compoundInner = this.responseData.innerComponents;
  //   this.warningText = this.responseData.additionalComponent;
  // },
  watch: {
  '$i18n.locale': {
    handler: 'updateText', // Вызовет метод updateText при изменении языка
    immediate: true, // Вызовет метод updateText сразу после создания компонента
  },
  responseData: {
    handler: 'updateText', // Вызовет метод updateText при изменении responseData
    immediate: true, // Вызовет метод updateText сразу после создания компонента
    deep: true, // Отслеживание изменений вложенных свойств responseData
  },
  },
  methods: {
    updateText(){
      this.compoundOuter = this.responseData.outerComponents;
    this.compoundInner = this.responseData.innerComponents;
    this.warningText = this.responseData.additionalComponent;
    }
  },
};
</script>
<style scoped>
.compound-header {
  color: var(--07, #000);
  font-family: Montserrat;
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  margin-right: 5px;
}
.compound-text {
  color: var(--07, #000);
  font-family: Montserrat;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  padding-bottom: 18px;
}
.horizontal-line {
  background: var(--02, #817635);
  height: 1px;
  width: 640px;
  margin-bottom: 10px;
}
.warning-text {
  color: var(--07, #000);
  font-family: Montserrat;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  padding: 15px 30px 15px 18px;
}
.warning-container {
  border-radius: 8px;
  border: 1px solid var(--03, #bcb648);
  background: var(--06, #fff);
  margin-bottom: 18px;
}
.compound-gmo {
  color: var(--02, #817635);
  font-family: Montserrat;
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
}
</style>
