<template>
    <div class="breadcrumbs">
      <span>
        <router-link to="/" class="breadcrumbs">Главная</router-link>
      </span>
      <span v-for="(crumb, index) in breadcrumbs" :key="index">
        <span class="separator">></span>
        <router-link :to="crumb.route" class="breadcrumbs" style="text-decoration:none">
          {{ crumb.label }}
        </router-link>
      </span>
    </div>
  </template>
  
  <script>
  export default {
    props: {
      routes: Array // Принимаем роуты через props
    },
    computed: {
        breadcrumbs() {
    const routes = this.$route.path.split('/').filter(route => route !== '');
    let path = '';
    const breadcrumbs = [];

    for (const route of routes) {
      path += `/${route}`;
      const matchedRoute = this.routes.find(r => r.to === path);

      if (matchedRoute) {
        const label = matchedRoute.meta && matchedRoute.meta.customLabel
          ? matchedRoute.meta.customLabel
          : matchedRoute.title
            ? matchedRoute.title
            : '';

        breadcrumbs.push({
          route: path,
          label: label || route
        });
      } else {
        breadcrumbs.push({
          route: path,
          label: route
        });
      }
    }

    // Убираем части URL, соответствующие формату UUID, с использованием регулярного выражения
    const filteredBreadcrumbs = breadcrumbs.map(crumb => {
      return {
        route: crumb.route.replace(/\/[a-f\d-]{36}\/?/i, ''), // Регулярное выражение для UUID
        label: crumb.label
      };
    });

    return filteredBreadcrumbs;
  }
    }
  };
  </script>
  <style>
  .separator {
    margin: 0px;
  }
  
  .breadcrumbs {
    color: var(--01, #603725)!important;
    font-family: Montserrat;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    margin-bottom: 32px;
    text-decoration: none;
  }
  /* Стили для хлебных крошек */
  </style>