<template>
  <div>
    <div class="d-sm-block d-mb-block d-lg-none" style="overflow-x: hidden">
      <div class="product-description-container">
        <p class="product-description-text">
            {{ this.productDescription}}
        </p>
        <div class="description-info-container-mobile">
          <div class="description-info-content py-1">
            <p class="description-info-header">{{ $t("bestbefore-date") }}</p>
            <p class="description-info-text">{{this.productExpDate}} {{ $t("month-date") }}</p>
          </div>
          <div class="vertical-line-mobile"></div>
          <div class="description-info-content py-1">
            <p class="description-info-header">
                {{ $t("energyVal-header") }}
            </p>
            <p class="description-info-text">{{this.productEnergyValue}}</p>
          </div>
        </div>
        <div class="description-nutrition-facts-mobile">
          <div class="nutrition-tab-header">
            <p class="nutrition-header-mobile">{{ $t("nutrituinVal-header") }}</p>
          </div>
          <div v-for="(item, index) in this.productNutrition.filter(nutr => nutr.value != 0)" :key="index" class="description-nutrition-content-mobile py-1" >
            <p class="description-nutrition-header">{{ getNutritionTranslation(item.type) }}</p>
            <p class="description-nutrition-text">{{ item.value }} {{ $t("gramm") }}</p>
          </div>
        </div>
      </div>
    </div>
    <div class="d-none d-lg-block d-xl-block d-xxl-block">
      <div class="product-description-container">
        <p class="product-description-text">
        {{ this.productDescription }}
        </p>
        <div class="description-info-container">
          <div class="description-info-content py-1">
            <p class="description-info-header">{{ $t("bestbefore-date") }}</p>
            <p class="description-info-text">{{this.productExpDate}} {{ $t("month-date") }}</p>
          </div>
          <div class="vertical-line"></div>
          <div class="description-info-content py-1">
            <p class="description-info-header">
                {{ $t("energyVal-header") }}
            </p>
            <p class="description-info-text">{{this.productEnergyValue}}</p>
          </div>
        </div>
        <div class="description-nutrition-facts">
          <div class="nutrition-tab-header">
            <p class="nutrition-header">{{ $t("nutrituinVal-header") }}</p>
          </div>
          <div v-for="(item, index) in this.productNutrition.filter(nutr => nutr.value != 0)" :key="index" class="description-nutrition-content py-1" >
            <p class="description-nutrition-header">{{ getNutritionTranslation(item.type) }}</p>
            <p class="description-nutrition-text">{{ item.value }} {{ $t("gramm") }}</p>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  name: "ProductDescription",
  props: {
    responseData: {},
  },
  data() {
    return {
        productDescription: "",
        productEnergyValue:"",
        productExpDate:"",
        productNutrition: {},
        productNutritionRu:{
            Protein : 'белки:',
            Fats: "жиры:",
            SaturatedFats: "в том числе насыщенные:",
            Carbohydrates: "yглеводы:",
            Sugar: "в том числе сахар:",
            Cellulose: "клетчатка:",
            Salt: "соль:"
        },
        productNutritionEn:{
            Cellulose: "Fibers",
        }
        
    };
  },
  // created() {
  //   this.productDescription = this.responseData.description;
  //   this.productEnergyValue = this.responseData.energyValue;
  //   this.productExpDate = this.responseData.bestBeforeMonths;
  //   this.productNutrition = this.responseData.nutritionalValues;
  // },
  methods: {
    getNutritionTranslation(nutrition) {
            // Попытайтесь получить локаль из локального хранилища
        const savedLocale = this.$i18n.locale;
        // Если локаль найдена в локальном хранилище, установите ее
        if (savedLocale === "ru") {
            return this.productNutritionRu[nutrition] || nutrition;
        }
        else {
            return this.productNutritionEn[nutrition] || nutrition;
        }
    },
    updateText(){
      this.productDescription = this.responseData.description;
    this.productEnergyValue = this.responseData.energyValue;
    this.productExpDate = this.responseData.bestBeforeMonths;
    this.productNutrition = this.responseData.nutritionalValues;
    }
    // getEnglishAppTranslation(app) {
    //   for (const key in this.appTranslations) {
    //     if (Object.prototype.hasOwnProperty.call(this.appTranslations, key)) {
    //       if (this.appTranslations[key] === app) {
    //         return key;
    //       }
    //     }
    //   }
    //   return app;
    // },
  },
  watch: {
  '$i18n.locale': {
    handler: 'updateText', // Вызовет метод updateText при изменении языка
    immediate: true, // Вызовет метод updateText сразу после создания компонента
  },
  responseData: {
    handler: 'updateText', // Вызовет метод updateText при изменении responseData
    immediate: true, // Вызовет метод updateText сразу после создания компонента
    deep: true, // Отслеживание изменений вложенных свойств responseData
  },
},
};
</script>

<style scoped>
.description-nutrition-facts-mobile {
    display: flex;
    flex-direction: column;
    align-items: center;
    width:100%;
}
.nutrition-header-mobile {
    display: flex;
    flex-direction: column;
    color: var(--06, #fff);
    font-family: Montserrat;
    font-size: 14px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    padding: 9px 14px;
}
.description-nutrition-content-mobile {
    display: flex;
    align-items: center;
    flex-direction: row;
    border: 1px solid var(--02, #817635);
    background: var(--06, #fff);
    width: 302px;
    justify-content: space-between;
    padding: 8px 12px 5px 14px;
}
.description-info-container-mobile {
  text-align: start;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  margin-top: 19px;
  /* max-width: 388px; */
}
.product-description-text {
  color: var(--07, #000);
  font-family: Montserrat;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  margin-top: 19px;
}
.description-info-container {
  text-align: start;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin-top: 19px;
  /* max-width: 388px; */
}
.description-info-header {
  color: var(--07, #000);
  font-family: Montserrat;
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  white-space: normal;
}
.description-info-text {
  color: var(--07, #000);
  font-family: Montserrat;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  white-space: normal;
}
.description-info-content {
  display: inline;
}
.vertical-line {
  width: 1px;
  height: 69px;
  background: #bdbdbd;
  /* margin-left: 31px; */
}
.nutrition-header {
  color: var(--06, #fff);
  font-family: Montserrat;
  font-size: 14px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  padding: 9px 13px;
}
.nutrition-tab-header {
  background: var(--02, #817635);
  max-width: 303px;
  margin-top: 29px;
}
.description-nutrition-header {
  color: var(--07, #000);
  font-family: Montserrat;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  white-space: nowrap;
}
.description-nutrition-text {
  color: var(--07, #000);
  font-family: Montserrat;
  font-size: 14px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  white-space: normal;
}
.description-nutrition-content {
  display: flex;
  align-items: center;
  flex-direction: row;
  border: 1px solid var(--02, #817635);
  background: var(--06, #fff);
  width: 303px;
  justify-content: space-between;
  padding: 8px 12px 5px 14px;
}
@media screen and (min-width:643px) and (max-width: 950px) {
  /* Задаем высоту картинки на фоне в vh (например, 50vh) */
  .description-info-container-mobile {
    flex-direction: column;
  }
  .vertical-line-mobile {
    width: 343px;
    height: 1px;
    background: #bdbdbd;
    margin-top: 18px;
    margin-bottom: 13px;
  }
  .description-nutrition-facts-mobile {
    width:100%;
    align-items:flex-start;
}
}
@media screen and (min-width: 950px) and (max-width: 1263px) {
    .description-info-container-mobile {
    flex-direction: row;
  }
  .vertical-line-mobile {
    width: 1px;
  height: 69px;
  background: #bdbdbd;
  }
  .description-nutrition-facts-mobile {
    width:100%;
    align-items:flex-start;
}
}
</style>
